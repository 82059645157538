import axios from "axios";
import toastr from "toastr";
import { URL_CONFIG } from "../../_constants/Config/URL_CONFIG";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import moment from "moment";
import { customToastr } from "../../_Pages/_webapp/arya2.0/CustomToastr";
import {
  getCookie,
  setCrTokenCookie,
  setDeviceCookie,
} from "../../_Pages/_webapp/arya2.0/helperFunc";
import { prodTime, uatTime } from "../../_constants/Config/AryaConstants";
export const CANCEL_FROM_FRONT_END = "CANCELLED";
let obj = {
  baseURL: URL_CONFIG.API_URL,
};
if (URL_CONFIG.ENVIROEMENT !== "DEV") {
  obj = { ...obj, withCredentials: true };
}

const API_AUTH = axios.create(obj);

const logout = (url) => {
  let sessionLogout = localStorage.getItem("sessionLogout");
  let userDashBoardData = localStorage.getItem("userDashBoardData");

  if (sessionLogout) {
    var a = moment(sessionLogout);
    var b = moment(new Date());
    var c = Math.abs(a.diff(b, "minutes"));
    var minutes = c;
    let timeOutTime = window.location.href.includes("www.arya.ag")
      ? prodTime
      : uatTime;

    if (userDashBoardData) {
      if (minutes > timeOutTime) {
        customToastr({
          text: "Logout Successfull",
        });
        localStorage.removeItem("authToken");
        localStorage.removeItem("userDashBoardData");
        localStorage.removeItem("aryauserType");
        localStorage.removeItem("BDauthToken");
        localStorage.removeItem("BDDashBoardData");
        localStorage.clear();
        window.location.assign("/");
        return true;
      } else if (url !== "/disbursement-user-detail") {
        let session = new Date();
        localStorage.setItem("sessionLogout", session);
      }
    } else if (url !== "/disbursement-user-detail") {
      let session = new Date();
      localStorage.setItem("sessionLogout", session);
    }
  } else if (url !== "/disbursement-user-detail") {
    let session = new Date();
    localStorage.setItem("sessionLogout", session);
  }
  return false;
};

API_AUTH.interceptors.request.use(function (request) {
  const token = localStorage.getItem("authToken");
  request.headers.Authorization = token ? `Bearer ${token}` : "";

  if (!getCookie("Device-Id")) {
    setDeviceCookie();
  }
  if (!getCookie("arya_ag_cr_token")) {
    setCrTokenCookie();
  }
  // request.headers["Device-Id"] = getCookie("Device-Id");
  const lang = localStorage.getItem("lang");
  if (lang && lang.length > 0) {
    if (lang === "hindi") {
      request.headers["Lang"] = "hi";
    } else {
      request.headers["Lang"] = lang;
    }

    // if (lang === "hindi") {
    //   request.headers.Lang = "hi";
    // } else if (lang === "en") {
    //   request.headers.Lang = lang;
    // }
  } else {
    request.headers["Lang"] = lang ?? "en";
  }
  if (
    request.url.includes("blockchain/depositor") ||
    request.url.includes("blockchain/v1/bank") ||
    request.url.includes("/blockchain/v1/temporary-pledge-stats")
  ) {
    const hash = localStorage.getItem("user_hash");
    if (hash) {
      request.headers["User-Hash"] = hash;
    }
  }

  if (logout(request?.url)) {
    return Promise.reject(new Error("Request canceled"));
  }
  // var cookies = { };

  //  let c = document.cookie.split(";").reduce( (ac, cv, i) => Object.assign(ac, {[cv.split('=')[0]]: cv.split('=')[1]}), {});

  // console.log('cookie',document.cookie);
  // request.headers.Cookies = cookies['jsessionid'];

  // console.log('cookie',c);
  // }

  return request;
});

API_AUTH.interceptors.response.use((response) => {
  console.log("response", response);
  if (logout(response?.config?.url)) {
    return Promise.reject(new Error("Request canceled"));
  }
  return response;
});

API_AUTH.interceptors.response.use(undefined, (error) => {
  console.log("error", error);
  if (error?.message === CANCEL_FROM_FRONT_END) {
    return CANCEL_FROM_FRONT_END;
  }
  if (error?.response?.status === 401) {
    toastr.error("unauthorized");
    localStorage.removeItem("authToken");
    localStorage.removeItem("userDashBoardData");
    localStorage.removeItem("aryauserType");

    localStorage.removeItem("BDauthToken");
    localStorage.removeItem("BDDashBoardData");

    if (localStorage.getItem("isBD")) {
      localStorage.clear();
      window.location.assign("/internal/login");
    } else {
      localStorage.clear();
      window.location.assign("/");
    }
  }
  return Promise.reject(error);
});

API_AUTH.interceptors.response.use(undefined, (error) => {
  if (error?.response?.status === 420) {
    const kycMessage = localStorage.getItem("kycMessage");
    confirmAlert({
      title: kycMessage !== null ? kycMessage : "To Continue With Booking",
      message: "Please Complete Your KYC.",
      buttons: [
        {
          label: "OK",
          onClick: async () => {
            localStorage.setItem("openkyc", "openkyc");
            // window.location.assign("/admin/kyc")
          },
        },
        {
          label: "CANCEL",
          onClick: () => console.log("test"),
        },
      ],
    });
    // toastr.warning("Please Complete KYC")
  }
  return Promise.reject(error?.response);
});

export default API_AUTH;
