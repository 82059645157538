import { URL_CONFIG } from "./URL_CONFIG";

export const fileSizeLimit = 10485760;
export const fileSizeMax = 10;
export const public_key_encryption =
  URL_CONFIG?.ENVIROEMENT === "PRODUTION"
    ? `-----BEGIN PUBLIC KEY-----
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAmYevXIO+v6lhweQID7Ng
b+F6vyJEe+0HSav9NDuUa+NdkeK0No/0OSNgH1LhmQPXBzx7/IbzBpu7ianEo3Fj
V9hG5F3V4QhQ34ivYNnqIwheBDZrQcalDqczFAaPd98AcAS6TbJgfRbch5PSv0ki
cKdtxid3RASTYPcOjanIYtkX6Y/wjZmhSHE8Pll2CB80HLrNtOhCrPsnmjA6pcr9
3BW3K55Z6u3SPcxB7qNBxg+oRTj/RpOOsax/NysjfLAPrtozKI4XMnSI3JzrLNWm
e5BbMcakT+aQw1yoSrJFaOuHh+5guMswkk5yC+NSVnVVK+o8olIgNxhXEm1VVPNH
0ZkPzZgVUCX1AOeXFR1sRo/5gVYSM5lrNGf13Skd83OXajbQdKzO0o2Ro27EqoLF
2ZA7tjImivFikY096jiBcnd7qzRsMZ4Tq67iyYF/3zDPE6PErjObDuVFgzyN+KFd
a58ijefPxu8HYexMuZNH7+tvNyfX8VvPPJUGJRKpkirBZuSKU76IO9Y2fIbzdkNN
gxtZwMfAq0uKuWZP5NyFQLRIGQ0bbHqaUQU6wXK6xl2cMP1DMROpi43qVPk7mwIE
eOBmSStauXlcjsv6B5Yz+skJc7LOLqV5pB34qaZKiJzhwHLvqaVw7NJj/2DxSnPK
HyEAz+HQ69XMrNk8toHA7AcCAwEAAQ==
-----END PUBLIC KEY-----`
    : `-----BEGIN PUBLIC KEY-----
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEApRN40Cm1XBbg8CnU9FqV
mEr0rT6k1uLhsaakDA+p5h+lEKP6Yw4TtylRetWMiBTlt+lPCJvkTSo/0+mnylCK
76/3Y7iH+K44tBkXBFRiMlfo07dRA2qU2epHa8HFhLMGTiaqlQGj5H6WcJcMe4lP
uQ/rRSbL9giyCaGDrZM05c1QwCXbzt5N2cZUIONs3JrIMDem3aRX8U/bR+phP9cl
RlOmVri2RJHqrhNnZAOwzM1zH3ER3UUc4RYCS5NM0biBXSaTr53V9/T1d5MPr1Z6
Z9lxdwmDru4bcYvwICXARXoTTqgBboByUkFYb2W4x0JC9b9By9Cv3Vrsmt5tneqU
hYcDlrAlMGJzc3Xgf22bhGIducofxvRRmXY5uZKCU1N8SGT6GXbcvULZlipGX4l+
+on4bs4hd/Btuco2b3dwhV7GaTyIdEDkL9QGdhr7VbepsXhW2bEIlazWUKUq+TQe
hL50jNGpvgPqhWGYpjDynP5VmKYwas8vQcPL2S7448uWvntzsfT8nhZZjRShuTAg
c1UQZ0j6+MzzqpfgJLAJhPM1eeOrPOOGCmzcj6HLB2fQ/0qy0luYUDJHuiKBQGqx
Mab92/IBRwObGLeZMcVlKgopEr/j54ssujpX/LnrPOBl7jDs8lBl1vBdF7+1W8S7
RK/LNmzwrPzoKzYm9IxObtUCAwEAAQ==
-----END PUBLIC KEY-----`;
export const captchaKey = "6LcC8WIqAAAAAITxY7wofvrBayVdmRhzgZ7HuUND";

export const uatTime = 179;
export const prodTime = 29;
